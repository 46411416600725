export const pictures = edges => edges.map(edge => picture(edge.node))

export const picture = ({
  title,
  type,
  image,
  size,
  material,
  story,
  fields,
}) => {
  return { title, type, image, size, material, story, slug: fields.slug }
}
