import React from 'react'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { picture } from '../csvSelectors'
import styles from './picture.module.css'

const metadata = (size, material) => {
  if (material) {
    if (size) {
      return (
        <React.Fragment>
          {size} &middot; {material}
        </React.Fragment>
      )
    } else {
      return material
    }
  } else {
    return size
  }
}

const Picture = ({ data }) => {
  const pic = picture(data.pageData)
  return (
    <Layout currentPath={`/${pic.type}s`} slug={pic.slug}>
      <Helmet>
        <meta
          property="og:title"
          content={`${pic.title} by ${data.siteData.siteMetadata.author}`}
        />
        <meta
          property="og:image"
          content={
            data.siteData.siteMetadata.siteUrl +
            pic.image.childImageSharp.resize.src
          }
        />
      </Helmet>
      <div>
        <h1>{pic.title}</h1>
        <Img
          className={styles.picture}
          fluid={pic.image.childImageSharp.fluid}
        />
        <div>{metadata(pic.size, pic.material)}</div>
        <p>{pic.story}</p>
      </div>
    </Layout>
  )
}

export default Picture

export const query = graphql`
  query($slug: String!) {
    pageData: picturesCsv(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      title
      type
      size
      material
      story
      image {
        childImageSharp {
          fluid(
            maxWidth: 1000
            traceSVG: { background: "#41a7b3", color: "#1fe5bd" }
          ) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
          resize {
            src
          }
        }
      }
    }
    siteData: site {
      siteMetadata {
        author
        siteUrl
      }
    }
  }
`
