import React from 'react'
import { Link } from 'gatsby'

import styles from './Header.module.css'
import Navigation from './Navigation'

const Header = ({ siteTitle, currentPath }) => (
  <header className={styles.header}>
    <div>
      <Link className={styles.header_siteTitle} to="/">
        {siteTitle}
      </Link>
    </div>
    <Navigation currentPath={currentPath} />
  </header>
)

export default Header
