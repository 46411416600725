import React from 'react'
import { Link } from 'gatsby'
import styles from './Navigation.module.css'

const NavigationLink = ({ to, children, currentPath }) => {
  const linkClass =
    currentPath === to ? styles.navigation_linkActive : styles.navigation_link
  return (
    <Link className={linkClass} to={to}>
      {children}
    </Link>
  )
}

const Navigation = ({ currentPath }) => (
  <nav className={styles.navigation}>
    <NavigationLink to="/paintings" currentPath={currentPath}>
      Paintings
    </NavigationLink>
    <NavigationLink to="/drawings" currentPath={currentPath}>
      Drawings
    </NavigationLink>
    <NavigationLink to="/etchings" currentPath={currentPath}>
      Etchings
    </NavigationLink>
    <NavigationLink to="/exhibitions" currentPath={currentPath}>
      Exhibitions
    </NavigationLink>
    <NavigationLink to="/about" currentPath={currentPath}>
      About
    </NavigationLink>
  </nav>
)

export default Navigation
