import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header'
import styles from './Layout.module.css'

const generateTitle = (siteTitle, title) => {
  if (title) {
    return `${siteTitle} | ${title}`
  } else {
    return siteTitle
  }
}

const Layout = ({ children, currentPath = '', title, slug }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteUrl
          }
        }
      }
    `}
    render={data => {
      const pageTitle = generateTitle(data.site.siteMetadata.title, title)
      return (
        <React.Fragment>
          <Helmet>
            <html lang="en" prefix="og: http://ogp.me/ns#" />
            <title>{pageTitle}</title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <meta name="keywords" content={data.site.siteMetadata.keywords} />
            <meta property="og:title" content={pageTitle} />
            <meta
              property="og:description"
              content={data.site.siteMetadata.description}
            />
            <meta property="og:locale" content="en_GB" />
            <meta
              property="og:site_name"
              content={data.site.siteMetadata.title}
            />
            <meta
              property="og:url"
              content={data.site.siteMetadata.siteUrl + (slug || currentPath)}
            />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
          <div className={styles.container}>
            <Header
              siteTitle={data.site.siteMetadata.title}
              currentPath={currentPath}
            />
            <main className={styles.content}>{children}</main>
            <footer className={styles.footer}>
              <small>&copy; {new Date().getFullYear()} Peter Philip</small>
            </footer>
          </div>
        </React.Fragment>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
